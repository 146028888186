/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: var(--#{$black}100);
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: smooth;
  scroll-padding-top: $top-nav-height + 2;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: var(--#{$variable-prefix}bg);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: normal;
}

pre,
code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: map_get($spacers, 6);
  padding-bottom: map_get($spacers, 6);

  @include media-breakpoint-up(lg) {
    padding-top: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 8);
  }
}

//setting up font family for bootstrap components
input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: $font-family-sans-serif;
}

pre {
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: $font-weight-semi-bold;
}

label {
  margin-bottom: map-get($spacers, 2);
}

pre,
//-code,
kbd,
samp {
  font-size: $font-size-sm;
}

code {
  font-size: 87.5%;
}

ol,
ul {
  padding-left: 2.5rem;
}

a {
  text-decoration: none !important;
}