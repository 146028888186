.gallery-wraper {
  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1.2fr 1fr;
    grid-gap: 2.5rem;
    margin-top: -12.5rem;
  }

  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(2, 1fr);


  .img-wraper {
    &:nth-child(2) {
      grid-row: 1/3;
      grid-column: 2/3;
    }

    img {
      border-radius: 1.87rem;
      height: 100%;
      object-fit: cover;
    }
  }
}